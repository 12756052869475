import { IUser } from "src/models/UserModel";
import { IDelivery } from "../../../models/DeliveryModel";

export interface DeliveryData {
  _id: string;
  name: string;
  placeFrom: string;
  placeTo: string;
  vehicule: string;
  bagage: number;
  passenger: number;
  date: Date;
  distance: number;
  duration: string;
  totalCost: number;
  tarif: number;
  Country: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  LastName: string;
  Email: string;
  phoneNumber: string;
}

export interface UserDeliveryRequest {
  _id: string;
}

export interface UserDeliveryDecoderResponse {
  _id: string;
  name: string;
  placeFrom: string;
  placeTo: string;
  vehicule: string;
  bagage: number;
  passenger: number;
  date: Date;
  distance: number;
  duration: string;
  totalCost: number;
  tarif: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetAllDeliveriesResponse<T> {
  message: string;
  deliveryData: T[];
}

export interface deliveryIdInterface {
  _id: string;
}

export interface DeleteDeliveryResponse<T> {
  message: string;
  deletedUser: T;
}

export interface AddDeliveryRequest {
  _id: string;
  name: string;
  placeFrom: string;
  placeTo: string;
  vehicule: string;
  bagage: number;
  passenger: number;
  date: string;
  distance: number;
  duration: string;
  totalCost: number | null;
  tarif: number;
  FirstName: string;
  LastName: string;
  Email: string;
  phoneNumber: string;
  Country: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateDeliveryRequest {
  _id: string;
  name: string;
  placeFrom: string;
  placeTo: string;
  vehicule: string;
  bagage: number;
  passenger: number;
  date: Date;
  duration: string;
  distance: number;
  totalCost: number;
  tarif: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface AddDeliveryResponse<T> {
  message: string;
  delivery: T;
}

export const decodeDeliveriesResponse = (
  response: GetAllDeliveriesResponse<DeliveryData>
): IDelivery[] => {
  const data: DeliveryData[] = response.deliveryData;

  const deliveries: IDelivery[] = data.map((delivery) => ({
    id: delivery._id,
    name: delivery.name,
    placeFrom: delivery.placeFrom,
    placeTo: delivery.placeTo,
    bagage: delivery.bagage,
    vehicule: delivery.vehicule,
    passenger: delivery.passenger,
    date: delivery.date,
    distance: delivery.distance,
    totalCost: delivery.totalCost,
    tarif: delivery.tarif,
    createdAt: delivery.createdAt,
    updatedAt: delivery.updatedAt,
    firstName: delivery.firstName,
    LastName: delivery.LastName,
    Email: delivery.Email,
    phoneNumber: delivery.phoneNumber,
    Country: delivery.Country,
  }));

  return deliveries;
};

export const decodeUserDeliveryResponse = (
  response: UserDeliveryDecoderResponse
): number | null => {
  return response.totalCost;
};
