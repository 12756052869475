import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import "./HomePge/style/flexboxgrid.min.css";
import './HomePge/style/index.css';
import 'nprogress/nprogress.css';
import App from 'src/App';
import store from './redux/store';
ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);
