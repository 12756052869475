import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import SuspenseLoader from './components/SuspenseLoader';
import BaseLayout from './layouts/BaseLayout';
const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Home = Loader(
  lazy(() => import('src/content/HomePage'))
)
const Formulaire = Loader(
  lazy(() => import('src/content/FormulaireUser'))
);
const Delivery = Loader(
  lazy(() => import('src/content/FormulaireDelivery'))
);

const router: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/formulaire',
        element: <Formulaire />
      },
      {
        path: '/delivery',
        element: <Delivery />
      },
    ]
  },
];

export default router;
