import { useRoutes } from "react-router-dom";
import router from "src/router";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import { Helmet } from "react-helmet-async";

function App() {
  const content = useRoutes(router);
  return (
    <>
      <Helmet>
        <title>Best Paris Cab - Réserver chauffeur à France</title>
        <meta name="description" content="Réserver VTC Paris est une entreprise de chauffeurs privés professionnels disponibles 7j/7, 24h/24. Nous desservons petite et grande couronne sur un large périmètre grâce à notre service de VTC toutes distances à Paris. Découvrez dès à présent nos prestations et laissez-vous séduire par nos véhicules haut de gamme de 1 à 8 passagers." />
        <meta name="keywords" content="VTC, Réserver, chauffeur, Paris, France, best paris cab,best cab, best paris,cab paris,taxi paris" />
      </Helmet>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
