import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../global/endpoints";
import { IDelivery } from "../../../models/DeliveryModel";
import {
  AddDeliveryRequest,
  AddDeliveryResponse,
  DeleteDeliveryResponse,
  DeliveryData,
  GetAllDeliveriesResponse,
  UpdateDeliveryRequest,
  UserDeliveryDecoderResponse,
  UserDeliveryRequest,
  decodeDeliveriesResponse,
  deliveryIdInterface,
} from "./delivery.interface";

export const deliveryApi = createApi({
  reducerPath: "deliveryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.BASE_URL,
  }),
  tagTypes: ["Deliveries"],
  endpoints: (builder) => ({
    // Get All Deliveries
    getAlldeliveries: builder.query<IDelivery[], void>({
      query: () => ({
        url: "/delivery",
      }),
      providesTags: ["Deliveries"],
      transformResponse: (
        response: GetAllDeliveriesResponse<DeliveryData>
      ): IDelivery[] => decodeDeliveriesResponse(response),
    }),
    // Delete a delivery
    deleteDelivery: builder.mutation<
      DeleteDeliveryResponse<DeliveryData>,
      deliveryIdInterface
    >({
      query: (params) => ({
        url: `/delivery/${params._id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Deliveries"],
    }),
    // Add a delivery
    addDelivery: builder.mutation<
      AddDeliveryResponse<DeliveryData>,
      AddDeliveryRequest
    >({
      query: (body) => ({
        url: "/delivery",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Deliveries"],
    }),
    //Update delivery
    updateDelivery: builder.mutation<
      AddDeliveryResponse<DeliveryData>,
      UpdateDeliveryRequest
    >({
      query: (body) => ({
        url: `/delivery/${body._id}`,
        method: "PUT",
        body: {
          name: body.name,
          placeFrom: body.placeFrom,
          placeTo: body.placeTo,
          bagage: body.bagage,
          vehicule: body.vehicule,
          passenger: body.passenger,
          date: body.date,
          duration: body.duration,
          distance: body.distance,
          totalCost: body.totalCost,
          tarif: body.tarif,
          createdAt: body.createdAt,
          updatedAt: body.updatedAt,
        },
      }),
      invalidatesTags: ["Deliveries"],
    }),

    //Update User
    userDelivery: builder.query<
      UserDeliveryDecoderResponse[],
      UserDeliveryRequest
    >({
      query: (body) => ({
        url: `/delivery/${body._id}/estimate`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAlldeliveriesQuery,
  useDeleteDeliveryMutation,
  useAddDeliveryMutation,
  useUpdateDeliveryMutation,
  useUserDeliveryQuery,
} = deliveryApi;
